/* eslint-disable axo-frontend/directory-structure */
import {
  InsuranceContext,
  getInsuranceSourceTagFromSearchParams,
} from '@axo/insurance/feature/providers';
import { PageSpinner } from '@axo/insurance/ui';
import { createInsuranceRequestData } from '@axo/insurance/utils';
import {
  BreadcrumbProvider,
  EventLogProvider,
  KnownResourceType,
} from '@axo/shared/data-access/event-log';
import {
  useCreateInsuranceRequest,
  useInsuranceRequest,
} from '@axo/shared/data-access/hooks';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { insurance, insurance_request } from '@axo/shared/data-access/types';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { ReactNode, useContext, useEffect } from 'react';
import { OnEnd } from './InsuranceProvider';

type InsuranceContentProps = {
  children: ReactNode;
  onEnd: OnEnd;
  spinner?: ReactNode;
  sourceTag: insurance.InsuranceSourceTag;
};

export function InsuranceContent({
  children,
  onEnd,
  spinner = <PageSpinner />,
  sourceTag,
}: InsuranceContentProps) {
  const { state, url } = useContext(DataAccessContext);
  const {
    state: { application },
  } = useContext(LoanApplicationContext);
  const {
    state: { insurancePolicyReference: insurancePolicyID },
    dispatch,
  } = useContext(InsuranceContext);
  const createInsuranceRequest = useCreateInsuranceRequest();
  const fetchInsuranceRequest = useInsuranceRequest(
    createInsuranceRequest.data?.ID
  );

  const source = sourceTag ?? getInsuranceSourceTagFromSearchParams();

  useEffect(() => {
    dispatch({
      type: 'Set source',
      payload: source as insurance.InsuranceSourceTag,
    });
  }, [dispatch, source]);

  useEffect(() => {
    const data = fetchInsuranceRequest.data;
    if (!data) return;
    const { PolicyID } = data;
    if (
      fetchInsuranceRequest.data?.Status ===
        insurance_request.Status.Approved &&
      PolicyID
    ) {
      dispatch({
        type: 'Set insurance',
        scope: { parentType: 'insurance' },
        payload: data,
      });
    }
  }, [fetchInsuranceRequest.data, dispatch]);

  const { mutate: doCreate } = createInsuranceRequest;

  useEffect(() => {
    if (
      application?.PersonID &&
      application?.MarketCountry &&
      application.CustomerID &&
      !insurancePolicyID
    ) {
      const data = createInsuranceRequestData({
        application,
        source,
      });

      doCreate(data);
    }
  }, [application, insurancePolicyID, doCreate, source]);

  const isNew =
    fetchInsuranceRequest.data?.Status === insurance_request.Status.New;
  const TIMEOUT_MS = 60_000; // 1 minute
  const isOld = fetchInsuranceRequest.data
    ? +new Date() - +new Date(fetchInsuranceRequest.data?.CreatedAt) >
      TIMEOUT_MS
    : false; // 30 seconds
  const hasTimedOut = isNew && isOld;

  if (hasTimedOut) {
    const token = state.user.token;
    const uid = application?.ID;
    onEnd.action({ token, uid });
    return <>{spinner}</>;
  }

  if (
    createInsuranceRequest.isLoading ||
    fetchInsuranceRequest.isLoading ||
    fetchInsuranceRequest.data?.Status === insurance_request.Status.New
  ) {
    return <>{spinner}</>;
  }
  if (
    createInsuranceRequest.error ||
    fetchInsuranceRequest.data?.Status === insurance_request.Status.Denied
  ) {
    // Ineligible or already signed
    const token = state.user.token;
    const uid = application?.ID;
    onEnd.action({ token, uid });
    return <>{spinner}</>;
  }
  if (!insurancePolicyID) return <>{spinner}</>;

  return (
    <EventLogProvider
      token={state.user.token}
      apiURL={url.api}
      marketCountry="NO"
      source={'insurance-flow'}
    >
      <BreadcrumbProvider
        resources={[
          {
            Type: KnownResourceType.InsuranceRequest,
            ID: createInsuranceRequest.data?.ID ?? '',
          },
          {
            Type: KnownResourceType.InsurancePolicy,
            ID: insurancePolicyID.PolicyID!,
          },
        ]}
        fields={[{ Key: 'source-tag', Value: sourceTag }]}
      >
        {children}
      </BreadcrumbProvider>
    </EventLogProvider>
  );
}
