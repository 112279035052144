/* eslint-disable axo-frontend/directory-structure */
import { A, Icons, P } from '@axo/deprecated/util/ui-components';
import {
  prePurchaseInformationUrl,
  productFactSheetUrl,
} from '@axo/insurance/const/no';
import { StepContext } from '@axo/insurance/feature/providers';
import { NavWrapper, PageSpinner, StepNavigator } from '@axo/insurance/ui';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { currencyFormatter } from '../../currency';
import { useTranslation } from '../../translations/useTranslation';
import { insuranceCoverageKey } from '../SelectCoverage/hooks/useSaveDataOnLocalStorage';
import styles from './Summary.module.scss';

export const Summary = () => {
  const isSuccessEventSent = useRef(false);
  const { state, dispatch, onEnd, isEmbedded } = useContext(StepContext);
  const { state: dataAccessContext } = useContext(DataAccessContext);
  const { state: applicationContext } = useContext(LoanApplicationContext);
  const { t } = useTranslation();

  const search = new URLSearchParams(window.location.search);
  const isSuccess = search.get('success');
  const error = search.get('error');

  const log = useEventLogger();
  const { track } = useAnalytics();
  useEventField('current-step', 'summary');
  useEventField('is-payment-successful', isSuccess ?? false.toString());

  useEffect(() => {
    if (isSuccess) {
      if (isSuccessEventSent.current) return;

      isSuccessEventSent.current = true;
      track({
        event: 'Insurance Offer Completed',
      });
      return;
    }

    track({
      event: 'Insurance Offer Payment Aborted',
      params: {
        'error-message': error,
      },
    });

    if (!error) return;

    log(EventCode.PaymentFailed, { 'error-message': error });
    dispatch({
      type: 'Set error',
      scope: { parentType: 'error' },
      payload: 'Payment failed',
    });
  }, [dispatch, error, isSuccess, log, track]);

  async function handleNext() {
    log(EventCode.InsuranceNext);
    await track({
      event: 'Insurance Step summary Completed',
      options: {
        send_immediately: true,
      },
    });
    const token = dataAccessContext.user.token;
    const uid = applicationContext?.application?.ID;
    onEnd.action({ token, uid });
  }

  const insuranceValue = useMemo(() => {
    const stateData = state.steps.selectCoverage.insurance;

    if (stateData.payout && stateData.premium) {
      return {
        payout: stateData.payout,
        premium: stateData.premium,
      };
    }

    const valuesFromStorage = localStorage.getItem(insuranceCoverageKey);

    if (valuesFromStorage) {
      const valuesParsed = JSON.parse(valuesFromStorage);

      return {
        payout: valuesParsed.payout,
        premium: valuesParsed.premium,
      };
    }
  }, [state.steps.selectCoverage.insurance]);

  if (error || !isSuccess) return <PageSpinner />;

  return (
    <>
      <div className={styles.summary}>
        <div className={styles.content}>
          <div className={styles.shield}>
            <Icons.Shield size="lg" />
            <span className={styles.thankYou}>{t('Thank you')}!</span>
          </div>
          <div className={styles.congratulations}>
            {t('Congratulations with your insurance')}
          </div>
        </div>
        <div className={styles.infoBox}>
          <div className={styles.infoHeader}>
            <span>{t('Insurance information')}</span>
          </div>
          <div className={styles.highlightedItem}>
            {t('Will be sent to you by email')}
          </div>
          <div className={styles.infoItem}>
            <P className={styles.label}>{t('Monthly payout')}</P>
            <span className={styles.value}>
              {currencyFormatter(insuranceValue?.payout)}/{t('Month')}
            </span>
          </div>
          <div className={styles.infoItem}>
            <P className={styles.label}>{t('Monthly insurance cost')}</P>
            <span className={styles.value}>
              {currencyFormatter(insuranceValue?.premium)}/{t('Month')}
            </span>
          </div>
          <div className={styles.extraInfo}>
            <p className={styles.label}>{t('Downloadable information')}</p>
            <div className={styles.extraInfoLinkContainer}>
              <div className={styles.extraInfoLink}>
                <Icons.Pdf size="sm" />
                <A href={productFactSheetUrl} target="_blank" rel="noreferrer">
                  {t('Product Fact Sheet')}
                </A>
              </div>
              <div className={styles.extraInfoLink}>
                <Icons.Pdf size="sm" />
                <A
                  href={prePurchaseInformationUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Terms and Conditions')}
                </A>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavWrapper alwaysStickyNavButtons={isEmbedded}>
        <StepNavigator
          rightButton={{
            text: t('Continue'),
            variant: 'enabled',
            width: 'full-width',
            onClick: handleNext,
          }}
        />
      </NavWrapper>
    </>
  );
};
