import { Check } from '@axo/shared/ui/atoms';
import { FC, ReactNode } from 'react';
import sanitizeHtml from 'sanitize-html';
import classNames from '../../../utils/class-names';
import { P, Span } from '../../atoms/StyledElements';
import styles from './KeyBenefits.module.scss';

export interface IKeyBenefits {
  benefits: string[];
  checkmarkColor?: 'valid' | 'info';
  ingress?: string;
  classes?: ClassNames;
  icon?: ReactNode;
}

type ClassNames = {
  root?: string;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const KeyBenefits: FC<IKeyBenefits> = ({
  benefits,
  checkmarkColor = 'valid',
  ingress,
  classes,
  icon,
}: IKeyBenefits) => (
  <section className={classNames('KeyBenefits', styles.list, classes?.root)}>
    {ingress && <P>{ingress}</P>}
    {benefits.map((benefit) => (
      <div key={benefit} className={styles.listItem}>
        {icon !== undefined ? icon : <Check state={checkmarkColor} />}
        <Span
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(benefit) }}
        ></Span>
      </div>
    ))}
  </section>
);

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default KeyBenefits;
