/* eslint-disable axo-frontend/directory-structure */
import { Icons, Slider, Tooltip } from '@axo/deprecated/util/ui-components';
import { payoutAndPremium } from '@axo/insurance/const/no';
import { MyPageFlags } from '@axo/mypage/util';
import { useFlags } from '@axo/shared/services/feature-flags';
import { classNames } from '@axo/shared/util/dom';
import clsx from 'clsx';
import { currencyFormatter } from '../../currency';
import { useTranslation } from '../../translations/useTranslation';
import styles from './SelectCoverageSlider.module.scss';

export const SelectCoverageSlider = ({
  value,
  onChange,
}: {
  value: { payout: number; premium: number };
  onChange: (value: number) => void;
}) => {
  const { t } = useTranslation();
  const {
    'offer-insurance-on-accept': flag_offer_insurance_on_accept,
    'accept-insurance-widget': flag_accept_insurance_widget,
  } = useFlags(MyPageFlags);

  return (
    <div>
      <div
        className={clsx(
          styles.insuranceSummaryContainer,
          (flag_offer_insurance_on_accept || flag_accept_insurance_widget) &&
            styles.insuranceSummaryContainerNew
        )}
      >
        {!flag_offer_insurance_on_accept && !flag_accept_insurance_widget && (
          <p className={styles.insuranceLabel}>
            {t('Choose your insurance level')}
          </p>
        )}
        <div className={styles.insuranceSummary}>
          <div
            className={classNames(styles.insuranceSummaryItem, styles.payout)}
          >
            <div className={styles.payoutLabel}>
              {t('Payable in the event of damage')}
              <Tooltip
                text={t(
                  'You will start receiving the compensation once the insurance is activated'
                ).toString()}
                classes={{ text: styles.tooltipText }}
              >
                <div className={styles.iconContainer}>
                  <Icons.CircleInfoOutline
                    size="xs"
                    color={{ primary: '--info-500' }}
                  />
                </div>
              </Tooltip>
            </div>
            <p className={styles.value}>
              {currencyFormatter(value.payout)}/{t('Month')}
            </p>
          </div>
          <div
            className={classNames(
              styles.insuranceSummaryItem,
              styles.rightAlign
            )}
          >
            <p>{t('Monthly cost')}</p>
            <p className={styles.value}>
              {currencyFormatter(value.premium)}/{t('Month')}
            </p>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          styles.insuranceSlider,
          (flag_offer_insurance_on_accept || flag_accept_insurance_widget) &&
            styles.insuranceSliderNew
        )}
      >
        <Slider
          hideLegend
          min={payoutAndPremium[1].payout}
          max={payoutAndPremium[payoutAndPremium.length - 1].payout}
          step={1000}
          value={value.payout}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
