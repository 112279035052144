import { isBrowser } from '@axo/shared/util/dom';
import { CrossDomainAuthData } from '../CrossDomainAuth.types';

export const retrieveCrossDomainAuthRouteData = (): CrossDomainAuthData => {
  if (!isBrowser()) return { applicationId: undefined, token: undefined };

  const urlParams = new URLSearchParams(window.location.search);
  const hash = window.location.hash?.slice(1);
  const applicationId = urlParams.get('id') || undefined;

  // Cross Domain Auth consists of a combination of the application id and the token.
  if (!hash || !applicationId)
    return { applicationId: undefined, token: undefined };

  const hashParams = new URLSearchParams(
    hash.includes('&') ? hash : `?${hash}`
  );
  const token = hashParams.get('token') || undefined;

  return { applicationId, token };
};
