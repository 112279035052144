import jwtDecode, { JwtPayload } from 'jwt-decode';

export const getExpiryFromJwt = (jwt: string): number | null => {
  try {
    const decoded: JwtPayload = jwtDecode(jwt);
    if (!decoded.exp) {
      console.error('JWT does not contain expiration');
      return null;
    }
    return decoded.exp;
  } catch (error) {
    console.error('Invalid JWT', error);
    return null;
  }
};
