/* eslint-disable axo-frontend/directory-structure */
import { Icons } from '@axo/deprecated/util/ui-components';
import { useTranslation } from '../../translations/useTranslation';
import styles from './Explainer.module.scss';

export const ExplainerContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t('Before you proceed, would you like to add insurance')}</p>
      <ul className={styles.ul}>
        <li className={styles.listContent}>
          <Icons.Lifesaver size="sm" />
          {t('Take care of your payments with peace of mind in an emergency')}
        </li>
        <li className={styles.listContent}>
          <Icons.ShieldOutline size="sm" />
          {t(
            'Keep your finances stable when unemployment hits or you get sick'
          )}
        </li>
        <li className={styles.listContent}>
          <Icons.Money size="sm" />
          {t('The compensation is paid directly to your account')}
        </li>
        <li className={styles.listContent}>
          <Icons.Globe size="sm" />
          {t(
            'Ensure insurance is valid 24 hours a day and around the world for the first 12 months'
          )}
        </li>
        <li className={styles.listContent}>
          <Icons.Reload size="sm" />
          {t(
            'To change the amount of insurance claim or terminate your insurance at any time'
          )}
        </li>
        <li className={styles.listContent}>
          <Icons.Emergency size="sm" />
          {t(
            'Our Maksuturva insurance covers a lump sum in the event of death'
          )}
        </li>
      </ul>
    </>
  );
};
