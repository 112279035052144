import { isBrowser } from '@axo/shared/util/dom';

export const clearCrossDomainAuthRouteToken = (): void => {
  if (!isBrowser()) return;

  const hash = window.location.hash?.slice(1) ?? '';
  const hashParams = new URLSearchParams(
    hash.includes('&') ? hash : `?${hash}`
  );
  const urlParams = new URLSearchParams(window.location.search);
  const applicationId = urlParams.get('id');

  // Cross Domain Auth consists of a combination of the application id and the token.
  if (!applicationId) return;

  hashParams.delete('token');

  const newHash = hashParams.toString() ? `#${hashParams.toString()}` : '';

  window.history.replaceState(
    {},
    document.title,
    `${window.location.pathname}${window.location.search}${newHash}`
  );
};
