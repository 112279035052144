/* eslint-disable axo-frontend/directory-structure */
import { ISelectCoverage } from '@axo/insurance/feature/providers';
import { useEffect } from 'react';

export const insuranceCoverageKey = 'insuranceCoverage';

export const useSaveDataOnLocalStorage = (
  data: ISelectCoverage['insurance']
) => {
  useEffect(() => {
    localStorage.setItem(insuranceCoverageKey, JSON.stringify(data));
  }, [data]);
};
