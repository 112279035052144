import { insurance } from '@axo/shared/data-access/types';
import { createContext } from 'react';
import { StepperAction } from './actions';
import { StepperState } from './reducer';
import { emptyState } from './stepStates';

export type OnAccept = {
  action: (args?: any) => void;
};

export type OnEnd = {
  action: (args?: any) => void;
  summaryButtonText?: string;
};

export const StepContext = createContext<{
  state: StepperState;
  dispatch: React.Dispatch<StepperAction>;
  onAccept?: OnAccept;
  onEnd: OnEnd;
  onError: (error: Error) => void;
  onClose?: () => void;
  isEmbedded?: boolean;
  sourceTag: insurance.InsuranceSourceTag;
  initialStep?: keyof typeof emptyState.steps;
}>({
  state: emptyState,
  dispatch: () => undefined,
  onAccept: { action: () => undefined },
  onEnd: { action: () => undefined, summaryButtonText: 'Next' },
  onError: () => undefined,
  isEmbedded: false,
  sourceTag: 'application-form-flow',
  initialStep: 'explainer',
});
