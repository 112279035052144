import { Header } from '@axo/deprecated/util/ui-components';
import {
  AuthProvider,
  getInsuranceSourceTagFromSearchParams,
  WebSocketProvider,
} from '@axo/insurance/feature/providers';
import {
  getInitialAppContextState,
  getInitialDataAccessContextState,
  getInitialInsuranceContextState,
  InsuranceProvider,
  OnEnd,
} from '@axo/insurance/feature/providers/no';
import { DataAccessProvider } from '@axo/shared/data-access/provider';
import { LoanApplicationProvider } from '@axo/shared/feature/providers';
import { QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Outlet } from 'react-router-dom';
import { Error as ErrorPage } from '../../../components/Error';
import { insuranceSourceTag } from '../../../consts/sourceTag';
import styles from './Main.module.scss';

const queryClient = new QueryClient();

type MainProps = {
  onEnd: OnEnd;
  onError: (error?: Error) => void;
};
export function Main({ onEnd, onError }: MainProps) {
  const search = new URLSearchParams(window.location.search);
  const source = getInsuranceSourceTagFromSearchParams();

  return (
    <DataAccessProvider
      queryClient={queryClient}
      url={{
        api: import.meta.env.VITE_APP_API_URL,
        // ws: import.meta.env.VITE_APP_WS_URL,
      }}
      storedInitialState={getInitialDataAccessContextState(search)}
    >
      <LoanApplicationProvider initialState={getInitialAppContextState(search)}>
        <ErrorPage>
          <AuthProvider onError={onError}>
            <WebSocketProvider>
              <InsuranceProvider
                onEnd={onEnd}
                sourceTag={source ?? insuranceSourceTag}
                initialState={getInitialInsuranceContextState(search)}
              >
                <div className={styles.page}>
                  <Header country="no" header_links={[]} focusMode={false} />
                  <div className={styles.container}>
                    <Outlet />
                  </div>
                </div>
                <ReactQueryDevtools initialIsOpen={false} />
              </InsuranceProvider>
            </WebSocketProvider>
          </AuthProvider>
        </ErrorPage>
      </LoanApplicationProvider>
    </DataAccessProvider>
  );
}
