import { InsuranceSubHeading } from '@axo/insurance/ui';
import { MyPageFlags } from '@axo/mypage/util';
import { useFlags } from '@axo/shared/services/feature-flags';
import { classNames } from '@axo/shared/util/dom';
import { Heading } from '@axo/ui-core/components/typography';
import { ReactNode } from 'react';
import styles from './StepTitle.module.scss';

type IStepTitle = {
  desktopOnly?: boolean;
  title: ReactNode | undefined;
};

export function StepTitle({ desktopOnly = true, title }: IStepTitle) {
  const {
    'offer-insurance-on-accept': flag_offer_insurance_on_accept,
    'accept-insurance-widget': flag_accept_insurance_widget,
  } = useFlags(MyPageFlags);

  return flag_offer_insurance_on_accept || flag_accept_insurance_widget ? (
    <Heading size="xs" className={styles.heading}>
      {title}
    </Heading>
  ) : (
    <div
      className={classNames(desktopOnly && styles.desktopOnly, styles.padding)}
    >
      <InsuranceSubHeading>{title}</InsuranceSubHeading>
    </div>
  );
}
