/* eslint-disable axo-frontend/directory-structure */
import { Icons } from '@axo/deprecated/util/ui-components';
import { useTranslation } from '../../translations/useTranslation';

import { MyPageFlags } from '@axo/mypage/util';
import { useFlags } from '@axo/shared/services/feature-flags';
import styles from './SelectCoverageTitle.module.scss';

export const SelectCoverageTitle = () => {
  const { t } = useTranslation();

  const {
    'offer-insurance-on-accept': flag_offer_insurance_on_accept,
    'accept-insurance-widget': flag_accept_insurance_widget,
  } = useFlags(MyPageFlags);

  if (!flag_offer_insurance_on_accept && !flag_accept_insurance_widget)
    return t('Product details');

  return (
    <div className={styles.selectCoverageTitleContainer}>
      <Icons.Shield size="lg" />
      <span className={styles.title}>{t('Axo insurance')}</span>
    </div>
  );
};
