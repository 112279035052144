import { useMutation } from 'react-query';
import { ws_connection_api } from '@axo/shared/data-access/api';
import { useAPI } from '@axo/shared/data-access/provider';

export const useCreateTicket = () => {
  const api = useAPI();

  const mutation = useMutation(() => ws_connection_api.createTicket(api)(), {
    onSuccess: (data) => {
      // console.log('Ticket created successfully:', data);
    },
    onError: (error) => {
      // console.error('Error creating ticket:', error);
    },
    retry: 5,
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // Exponential backoff: 1s, 2s, 4s, 8s, 16s, ... up to 30s
  });

  return mutation;
};
